import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-edit-link',
  templateUrl: './edit-link.component.html',
  styleUrls: ['./edit-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditLinkComponent {
  @Input() public text = '-';
  @Input() public isMobile = false;
}
