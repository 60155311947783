import {RouteItem} from '@core/enums';
import {Permission} from '@core/models/permission.interface';

export const PERMISSIONS: Permission[] = [
  {
    name: 'customer',
    permissions: ['customer'],
    pages: [
      {
        name: 'nav.products',
        href: RouteItem.PRODUCTS,
        icon: 'storefront',
      },
      {
        name: 'nav.orders',
        href: RouteItem.ORDERS,
        icon: 'list_alt',
      },
      {
        name: 'nav.custom_order',
        href: RouteItem.CUSTOM_ORDER,
        icon: 'build',
      },
      {
        name: 'nav.school',
        href: RouteItem.SCHOOL,
        icon: 'school',
      },
      {
        name: 'nav.documents',
        href: RouteItem.DOCUMENTS,
        icon: 'verified',
      },
      {
        name: 'nav.cart',
        href: RouteItem.CHECKOUT,
        hideInMenu: true,
      },
    ],
    homePage: RouteItem.PRODUCTS,
  },
  {
    name: 'pressman',
    permissions: ['pressman'],
    pages: [
      {
        name: 'nav.print',
        href: RouteItem.PRINT_TASKS,
        icon: 'playlist_add',
      },
      {
        name: 'nav.my-tasks',
        href: RouteItem.MY_TASKS,
        icon: 'playlist_play',
      },
      {
        name: 'nav.filament',
        href: RouteItem.FILAMENT,
        icon: 'support',
      },
      {
        name: 'nav.school',
        href: RouteItem.SCHOOL,
        icon: 'school',
      },
      {
        name: 'nav.production',
        href: RouteItem.PRODUCTION,
        icon: 'precision_manufacturing',
      },
      {
        name: 'nav.printers',
        href: RouteItem.PRINTERS,
        hideInMenu: true,
      },
    ],
    homePage: RouteItem.PRINT_TASKS,
  },
  {
    name: 'curator',
    permissions: ['curator'],
    pages: [
      {
        name: 'nav.products_management',
        href: RouteItem.PRODUCT_MANAGEMENT,
        icon: 'factory',
      },
      {
        name: 'nav.requests',
        href: RouteItem.REQUESTS,
        icon: 'swap_vert_circle',
      },
      {
        name: 'nav.customer-orders',
        href: RouteItem.CUSTOMER_ORDERS,
        icon: 'shopping_cart',
      },
      {
        name: 'nav.fulfillment-report',
        href: RouteItem.FILFILLMENT_REPORTS,
        icon: 'local_shipping',
      },
      {
        name: 'nav.school',
        href: RouteItem.SCHOOL,
        icon: 'school',
      },
      {
        name: 'nav.quality-check',
        href: RouteItem.QUALITY_CHECK,
        icon: 'thumbs_up_down',
      },
      // {
      //   name: 'nav.order-review',
      //   href: RouteItem.CUSTOMER_REVIEW,
      // },
    ],
    homePage: RouteItem.PRODUCT_MANAGEMENT,
  },
  {
    name: 'uber_curator',
    permissions: ['uber_curator'],
    pages: [
      {
        name: 'nav.statistics',
        href: RouteItem.STATISTICS,
        icon: 'analytics',
      },
    ],
    homePage: RouteItem.PRODUCT_MANAGEMENT,
  },
  {
    name: 'assistant',
    permissions: ['assistant'],
    pages: [
      {
        name: 'nav.statistics',
        href: RouteItem.STATISTICS,
        icon: 'analytics',
      },
    ],
    homePage: RouteItem.PRODUCT_MANAGEMENT,
  },
  {
    name: 'fond_curator',
    permissions: ['fond_curator'],
    pages: [
      {
        name: 'nav.statistics',
        href: RouteItem.STATISTICS,
        icon: 'analytics',
      },
    ],
    homePage: RouteItem.PRODUCT_MANAGEMENT,
  },
  {
    name: 'new_customer',
    permissions: ['new_customer'],
    pages: [
      {
        name: 'nav.verification',
        href: RouteItem.VERIFICATION,
      },
    ],
    homePage: RouteItem.VERIFICATION,
  },
  {
    name: 'new_pressman',
    permissions: ['new_pressman'],
    pages: [
      {
        name: 'nav.print',
        href: RouteItem.PRINT_TASKS,
        icon: 'playlist_add',
      },
      {
        name: 'nav.my-tasks',
        href: RouteItem.MY_TASKS,
        icon: 'playlist_play',
      },
      {
        name: 'nav.filament',
        href: RouteItem.FILAMENT,
        icon: 'support',
      },
      {
        name: 'nav.school',
        href: RouteItem.SCHOOL,
        icon: 'school',
      },
      {
        name: 'nav.production',
        href: RouteItem.PRODUCTION,
        icon: 'precision_manufacturing',
      },
      {
        name: 'nav.printers',
        href: RouteItem.PRINTERS,
        hideInMenu: true,
      },
    ],
    homePage: RouteItem.PRINT_TASKS,
  },
  {
    name: 'developer',
    permissions: ['developer'],
    pages: [
      {
        name: 'nav.products',
        href: RouteItem.PRODUCTS,
        icon: 'storefront',
      },
      {
        name: 'nav.orders',
        href: RouteItem.ORDERS,
        icon: 'list_alt',
      },
      {
        name: 'nav.custom_order',
        href: RouteItem.CUSTOM_ORDER,
        icon: 'build',
      },
      {
        name: 'nav.school',
        href: RouteItem.SCHOOL,
        icon: 'school',
      },
    ],
    homePage: RouteItem.PRODUCTS,
  },
  {
    name: 'observer',
    permissions: ['observer'],
    pages: [
      {
        name: 'nav.products',
        href: RouteItem.PRODUCTS,
        icon: 'storefront',
      },
    ],
    homePage: RouteItem.PRODUCTS,
  },
];
