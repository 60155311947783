import {Directive, HostListener, inject} from '@angular/core';
import {MatTooltip} from '@angular/material/tooltip';

@Directive({
  selector: '[matTooltip]',
})
export class ClickableTooltipDirective {
  private matTooltip = inject(MatTooltip);

  @HostListener('click')
  onClick() {
    this.matTooltip.show();
  }
}
