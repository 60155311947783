<div *ngIf="status == 'awaiting_documents' && paymentSource; else defStatus">
  <div class="flex">
    <div class="status" [ngClass]="status">
      <span>{{ 'order_states.' + paymentSource | translate }}</span>
    </div>
    <button
      *ngIf="paymentSource == 'official_letter' || paymentSource == 'self_cost'"
      mat-mini-fab
      color="primary"
      (click)="onUpload($event)"
    >
      <mat-icon>upload</mat-icon>
      <input type="file" (change)="onFileChange($event)" />
    </button>
  </div>
  <a
    *ngIf="paymentSource == 'financial_verification' || paymentSource == 'community'"
    [routerLink]="['/' + RouteItem.DOCUMENTS]"
    (click)="$event.stopPropagation()"
    >{{ 'verification_link' | translate }}</a
  >
  <a
    *ngIf="paymentSource == 'official_letter'"
    [routerLink]="['/' + RouteItem.DOCUMENTS]"
    target="_blank"
    [queryParams]="{step: 2}"
    (click)="$event.stopPropagation()"
    >{{ 'link_official_letter' | translate }}</a
  >
</div>

<ng-template #defStatus>
  <div class="status" [ngClass]="status">
    <span>{{ 'order_states.' + status | translate }}</span>
  </div>
</ng-template>
