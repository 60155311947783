import {Directive, HostBinding, HostListener, Input, OnChanges, SimpleChanges} from '@angular/core';
import {environment} from '@env/environment.dev';

@Directive({
  selector: 'img[appImageState]',
})
export class ImageStateDirective implements OnChanges {
  @Input() type: string | undefined;
  @HostBinding('src') @Input() src: string | undefined | {url: string; type: string}[];

  @HostListener('error') updateUrl() {
    this.setDefaultImg();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['src'] && !changes['src'].currentValue) {
      this.setDefaultImg();
    }
  }

  private setDefaultImg() {
    this.src = environment.defaults[this.type === 'avatar' ? 'userAvatar' : 'productImage'];
  }
}
