import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-dnd-progress',
  templateUrl: './dnd-progress.component.html',
  styleUrls: ['./dnd-progress.component.scss'],
})
export class DndProgressComponent {
  @Input() progress = 0;
}
