import {ThemeService} from '@services/theme.service';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {UserService} from '@services/user/user.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceComponent {
  homeUrl$ = this.userService.homeUrl$;
  theme$ = this.themeService.currentTheme$;

  constructor(
    private userService: UserService,
    private themeService: ThemeService,
    private router: Router,
  ) {
    document.getElementById('intro')?.classList.add('hidden');
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 30 * 1000);
  }
}
