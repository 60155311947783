import {NgModule} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {MaterialModule} from './material.module';

@NgModule({
  imports: [MaterialModule],
})
export class IconModule {
  private path = 'assets/icons';

  constructor(
    private domSanitizer: DomSanitizer,
    public matIconRegistry: MatIconRegistry,
  ) {
    this.matIconRegistry.addSvgIcon('nova-poshta', this.setPath(`${this.path}/nova-poshta.svg`));
    this.matIconRegistry.addSvgIcon('star', this.setPath(`${this.path}/star.svg`));
    this.matIconRegistry.addSvgIcon('feedback_icon', this.setPath(`${this.path}/feedback_icon.svg`));
    this.matIconRegistry.addSvgIcon('avatar', this.setPath(`${this.path}/avatar.svg`));
    this.matIconRegistry.addSvgIcon('avatar_soldier', this.setPath(`${this.path}/avatar_soldier.svg`));
    this.matIconRegistry.addSvgIcon('edit', this.setPath(`${this.path}/pencil-edit.svg`));
    this.matIconRegistry.addSvgIcon('moon', this.setPath(`${this.path}/theme-dark.svg`));
    this.matIconRegistry.addSvgIcon('sun', this.setPath(`${this.path}/theme-light.svg`));
    this.matIconRegistry.addSvgIcon('trash', this.setPath(`${this.path}/trash.svg`));
    this.matIconRegistry.addSvgIcon('check', this.setPath(`${this.path}/check.svg`));
    this.matIconRegistry.addSvgIcon('star-filled', this.setPath(`${this.path}/star-filled.svg`));
    this.matIconRegistry.addSvgIcon('star-outlined', this.setPath(`${this.path}/star-outlined.svg`));
  }

  private setPath(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
