import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-recipient-info',
  templateUrl: './recipient-info.component.html',
  styleUrls: ['./recipient-info.component.scss'],
})
export class RecipientInfoComponent implements OnInit {
  @Input() address = '';
  @Input() notes = '';

  constructor() {}

  ngOnInit() {}
}
