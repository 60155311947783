import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '@services/user/user.service';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  @Input() navigate = true;
  lang$ = new BehaviorSubject<string>('uk');
  homeUrl$ = this.userService.homeUrl$;

  constructor(
    private translate: TranslateService,
    private userService: UserService,
  ) {
    this.translate.onLangChange.subscribe((data) => {
      this.lang$.next(data.lang);
    });
  }
}
