import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

export interface SnackBarOption {
  btnText?: string;
  duration?: number;
  verticalPosition?: MatSnackBarVerticalPosition;
}

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  defaultOption: SnackBarOption = {
    btnText: 'Ok',
    duration: 15000,
    verticalPosition: 'top',
  };
  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {}

  error(message: string, option: SnackBarOption = this.defaultOption): void {
    this.open(message || 'Error', 'error-snackbar', option);
  }

  success(message: string, option: SnackBarOption = this.defaultOption): void {
    this.open(message, 'success-snackbar', {...option, duration: 7000});
  }

  private open(message: string, className: string, option: SnackBarOption = this.defaultOption) {
    this.snackBar.open(
      message,
      this.translate.instant(option.btnText ? option.btnText : this.defaultOption.btnText || ''),
      {
        duration: option.duration ? option.duration : this.defaultOption.duration,
        panelClass: className,
        verticalPosition: option.verticalPosition || 'top',
      },
    );
  }
}
