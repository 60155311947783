import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, map} from 'rxjs';
import {SessionService} from '@services/session/session.service';
import {environment} from '@env/environment';
import {UserService} from '@services/user/user.service';

@Injectable()
export class AuthGuard {
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private userService: UserService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isLogged = this.sessionService.isTokenInActualState();

    if (!isLogged) {
      this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}}).catch();
      return false;
    }

    return this.userService.user$.pipe(
      map((user) => {
        const userRoles = user?.roles.map((x) => x.name) || [];
        const hasAccess = environment.roles.some((value) => userRoles.some((element) => value.includes(element)));

        if (!hasAccess) {
          this.router.navigate(['/login']).catch();
          return false;
        }
        return true;
      }),
    );
  }
}
