import {Injectable} from '@angular/core';
import {ApiService} from '@services/api.service';
import {AuthResponseInterface} from '@core/models';
import {HttpClient} from '@angular/common/http';
import {SessionService} from '@services/session/session.service';
import {tap} from 'rxjs/operators';
import {UserService} from '@services/user/user.service';
import {SnackBarService} from './snack-bar.service';
import {environment} from '@env/environment';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie';
import {TelegramUser} from '@core/models/telegram-user';
import {Observable} from 'rxjs';

@Injectable()
export class AuthService extends ApiService {
  override readonly entityPath: string = 'auth';
  override readonly entityName: string = 'Auth';
  override readonly uiErrorHandler = this.snackBarService;

  constructor(
    protected override http: HttpClient,
    private sessionService: SessionService,
    private userService: UserService,
    private snackBarService: SnackBarService,
    private i18n: TranslateService,
    private cookieService: CookieService,
  ) {
    super(http);
  }

  public login(email: string, password: string, returnUrl: string) {
    return this.post<AuthResponseInterface>('login', {email, password, source: environment.env}, 'Login').pipe(
      tap((response) => {
        this.sessionService.setSession(response?.token);
        this.userService.initUser(true, returnUrl);
      }),
    );
  }

  public register(email: string, password: string, role: string) {
    return this.post<AuthResponseInterface>(
      'register',
      {
        email,
        password,
        password_confirmation: password,
        role,
        source: environment.env,
        referrer: this.cookieService.get('referrer'),
      },
      'Register',
      {
        throwError: true,
      },
    ).pipe(
      tap((response) => {
        if (response?.token) {
          this.setAuthorizationData(response);
        }
      }),
    );
  }

  unlockRequest(email: string) {
    return this.post<AuthResponseInterface>('unlock', {email, source: environment.env}, 'Unlock request');
  }

  unlock(unlock_token: string) {
    return this.put<AuthResponseInterface>('unlock', {unlock_token, source: environment.env}, 'Unlock');
  }

  confirmationRequest(email: string) {
    return this.post<AuthResponseInterface>('confirmation', {email, source: environment.env}, 'Confirmation request');
  }

  confirmation(confirmation_token: string) {
    return this.put<AuthResponseInterface>(
      'confirmation',
      {confirmation_token, source: environment.env},
      'Confirmation',
    );
  }

  resetPasswordRequest(email: string) {
    return this.post<AuthResponseInterface>('reset', {email, source: environment.env}, 'Reset password request');
  }

  resetPassword(reset_password_token: string, password: string) {
    return this.put<AuthResponseInterface>(
      'reset',
      {reset_password_token, password, password_confirmation: password, source: environment.env},
      'Reset password request',
    );
  }

  public googleAuth(user: any, returnUrl: string) {
    return this.http
      .post(
        `${environment.apiUrl}/auth/google/tokensignin`,
        {
          ...user,
          provider: 'GOOGLE',
          id_token: user.idToken,
        },
        {observe: 'response'},
      )
      .subscribe({
        next: (response: any) => {
          if (response.body?.token) {
            this.sessionService.setSession(response.body.token);
            this.userService.initUser(true, returnUrl);
          } else {
            console.log('no token: ', response);
          }
        },
        error: (error) => {
          if (error.status == 404) {
            this.googleSignup(user);
          } else {
            this.snackBarService.error('Something bad happened; please try again later.');
          }
        },
      });
  }

  telegramLink(user: TelegramUser): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/auth/telegram/link`, user).pipe(
      tap(() =>
        this.userService.updateUser({
          ...this.userService.user!,
          telegram: user.username,
        }),
      ),
    );
  }

  telegramUnlink(): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/auth/telegram/unlink`, null).pipe(
      tap(() =>
        this.userService.updateUser({
          ...this.userService.user!,
          telegram: null,
        }),
      ),
    );
  }

  getDiscordRedirectUrl(): string {
    return window.location.origin + '/discord-link';
  }

  discordLink(code: string): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/auth/discord/link`, {
      code,
      redirect_url: this.getDiscordRedirectUrl(),
    });
  }

  private googleSignup(user: any) {
    const password = this.password();
    this.http
      .post(
        `${environment.apiUrl}/auth/register`,
        {
          email: user.email,
          password,
          password_confirmation: password,
          role: 'new_customer',
          source: environment.env,
          referrer: this.cookieService.get('referrer'),
          google: {
            ...user,
            id_token: user.idToken,
          },
        },
        {observe: 'response'},
      )
      .subscribe({
        next: (response: any) => {
          if (response.body?.token) {
            this.sessionService.setSession(response.body?.token);
            this.userService.initUser(true);
          } else {
            console.log('no token: ', response);
          }
        },
        error: (error) => {
          if (error.status == 422) {
            this.snackBarService.error(this.i18n.instant('auth.need_email_confirm'));
          }
        },
      });
  }

  private password() {
    return Math.random().toString(36).slice(-11);
  }

  private setAuthorizationData(data: AuthResponseInterface) {
    this.sessionService.setSession(data?.token);
    this.userService.initUser();
  }
}
