import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-remove-filters',
  templateUrl: './remove-filters.component.html',
  styleUrls: ['./remove-filters.component.scss'],
})
export class RemoveFiltersComponent {
  @Output() click = new EventEmitter<void>();
}
