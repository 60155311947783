import {NgModule} from '@angular/core';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
  faArrowCircleDown,
  faBatteryHalf,
  faBinoculars,
  faBomb,
  faBook,
  faCubes,
  faFighterJet,
  faMedkit,
  faMicrochip,
  faPaperPlane,
  faPlusSquare,
  faRocket,
  faSatelliteDish,
  faSignLanguage,
  faWifi,
  faCrosshairs,
  faPersonMilitaryRifle,
} from '@fortawesome/free-solid-svg-icons';

@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class AwesomeIconsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faPlusSquare);
    library.addIcons(faFighterJet);
    library.addIcons(faBook);
    library.addIcons(faMedkit);
    library.addIcons(faWifi);
    library.addIcons(faBinoculars);
    library.addIcons(faArrowCircleDown);
    library.addIcons(faRocket);
    library.addIcons(faBatteryHalf);
    library.addIcons(faCubes);
    library.addIcons(faPaperPlane);
    library.addIcons(faMicrochip);
    library.addIcons(faSignLanguage);
    library.addIcons(faSatelliteDish);
    library.addIcons(faBomb);
    library.addIcons(faCrosshairs);
    library.addIcons(faPersonMilitaryRifle);
  }
}
