export const environment = {
  production: false,
  debugRoutes: false,
  env: 'dev',
  apiUrl: 'https://apidev.drukarmy.org.ua/api',
  roles: [
    'admin',
    'curator',
    'customer',
    'pressman',
    'new_pressman',
    'new_customer',
    'banned',
    'developer',
    'assistant',
    'fond_curator',
    'uber_curator',
    'observer',
  ],
  defaults: {
    userAvatar: 'assets/images/user-avatar.png',
    productImage: 'assets/images/product-image.jpg',
  },
  novaPoshtaApiKey: '77a9fdd968e4d583b58a2a42a089296c',
  maxProductOrder: 10000,
  inviteRedirect: 'https://apidev.drukarmy.org.ua/inv/',
  otherPrinterId: 3,
  clientAppUrl: 'https://app.drukarmy.org.ua/',
  isAdminPage: true,
};
