import {Directive, HostBinding, Renderer2, ElementRef, SimpleChanges, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[loader]',
})
export class LoaderDirective implements OnInit {
  @HostBinding('style.position')
  hostPosition: string = 'relative';

  @Input() loader: boolean | null = false;

  overflow?: any;

  constructor(
    private targetEl: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    const loadingContainer = this.renderer.createElement('div');
    this.renderer.addClass(loadingContainer, 'drop-file-overflow');
    this.renderer.setStyle(loadingContainer, 'display', this.loader ? 'flex' : 'none');
    this.renderer.setStyle(loadingContainer, 'justify-content', 'center');
    this.renderer.setStyle(loadingContainer, 'align-items', 'center');
    this.renderer.setStyle(loadingContainer, 'position', 'absolute');
    this.renderer.setStyle(loadingContainer, 'top', '0');
    this.renderer.setStyle(loadingContainer, 'background', 'gray');
    this.renderer.setStyle(loadingContainer, 'width', '100%');
    this.renderer.setStyle(loadingContainer, 'height', '100%');
    this.renderer.setStyle(loadingContainer, 'min-height', '300px');
    this.renderer.setStyle(loadingContainer, 'min-width', '270px');
    this.renderer.setStyle(loadingContainer, 'opacity', '0.3');
    this.renderer.setStyle(loadingContainer, 'z-index', '2');
    this.renderer.setStyle(loadingContainer, 'border-radius', '10px');

    this.renderer.appendChild(this.targetEl.nativeElement, loadingContainer);

    this.overflow = loadingContainer;

    const spinnerContainer = this.renderer.createElement('div');
    this.renderer.addClass(spinnerContainer, 'loader-container');
    this.renderer.appendChild(this.overflow, spinnerContainer);

    // custom spinner
    const spinner = this.renderer.createElement('div');
    this.renderer.addClass(spinner, 'loader');
    this.renderer.appendChild(spinnerContainer, spinner);
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (this.overflow && simpleChanges['loader']) {
      this.renderer.setStyle(this.overflow, 'display', this.loader ? 'flex' : 'none');
    }
  }
}
