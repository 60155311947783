import {Component, EventEmitter, Input, Output, ChangeDetectionStrategy, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-quantity-selector',
  templateUrl: './quantity-selector.component.html',
  styleUrls: ['./quantity-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuantitySelectorComponent implements OnInit {
  @Input() value = 1;
  @Input() minValue = 0;
  @Input() maxValue?: number;
  @Input() minStep = 1;
  @Output() onChange = new EventEmitter<number>();

  form = this.fb.group({
    quantity: [0],
  });

  constructor(private fb: FormBuilder) {
    this.form.controls.quantity.valueChanges.subscribe((v) => {
      let value: number = v ? parseInt(v as any) : 0;
      if (value < this.minValue) {
        value = this.minValue;
      }
      if (this.maxValue && value > this.maxValue) {
        value = this.maxValue;
      }
      if (v != value) {
        this.form.patchValue({quantity: value});
      }
      this.emit(value);
    });
  }

  ngOnInit(): void {
    this.form.patchValue({quantity: this.value});
  }

  increment(event: Event) {
    event.stopPropagation();
    let value = parseInt(this.form.value.quantity as any) || 0;
    if (value == this.maxValue) {
      return;
    }
    value += this.minStep;
    if (this.maxValue && value > this.maxValue) {
      value = this.maxValue;
    }
    this.form.patchValue({quantity: value});
    this.emit(value);
  }

  decrement(event: Event) {
    event.stopPropagation();
    let value = parseInt(this.form.value.quantity as any) || 0;
    if (value == this.minValue) {
      return;
    }
    value -= this.minStep;
    if (value < this.minValue) {
      value = this.minValue;
    }
    this.form.patchValue({quantity: value});
    this.emit(value);
  }

  private emit(value: number) {
    this.onChange.emit(value);
  }
}
