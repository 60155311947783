<div class="hGroup hCentered removeFilters" (click)="click.emit()" matRipple>
  <mat-icon style="text-align: center; padding-bottom: 4px">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M9.00052 7.93987L12.7128 4.22754L13.7734 5.2882L10.0612 9.00052L13.7734 12.7128L12.7128 13.7734L9.00052 10.0612L5.2882 13.7734L4.22754 12.7128L7.93987 9.00052L4.22754 5.2882L5.2882 4.22754L9.00052 7.93987Z"
        fill="#525A67"
      />
    </svg>
  </mat-icon>
  <div>{{ 'filters.drop' | translate }}</div>
</div>
