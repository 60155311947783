<mat-card class="container" appearance="outlined">
  <button mat-icon-button type="button" aria-label="Minus" (click)="decrement($event)" class="button">
    <mat-icon>remove_circle_outline</mat-icon>
  </button>
  <form [formGroup]="form">
    <input numberInput type="text" inputmode="numberic" formControlName="quantity" class="input" />
  </form>
  <button mat-icon-button aria-label="Plus" type="button" (click)="increment($event)" class="button">
    <mat-icon>add_circle_outline</mat-icon>
  </button>
</mat-card>
