import {Component} from '@angular/core';
import {InOutOpacityAnimation} from '@app/shared/animations/animations';
import {map} from 'rxjs';
import {ThemeService} from '@services/theme.service';
import {ThemeOptions} from '@core/enums/theme-options.enum';

const AnimationDuration = 250;

@Component({
  selector: 'app-theme-selector',
  templateUrl: './theme-selector.component.html',
  styleUrls: ['./theme-selector.component.scss'],
  animations: InOutOpacityAnimation(AnimationDuration),
})
export class ThemeSelectorComponent {
  protected iconState = '*';

  protected icon$ = this.themeService.currentTheme$.pipe(
    map((currentTheme) => (currentTheme === ThemeOptions.DARK ? 'sun' : 'moon')),
  );

  constructor(private themeService: ThemeService) {}

  initiateToggle() {
    this.iconState = 'void';
    setTimeout(() => {
      this.iconState = '*';
      this.themeService.toggleTheme();
    }, AnimationDuration);
  }
}
