import {AppService} from '@services/app.service';
import {Component, HostListener, OnInit} from '@angular/core';
import {UserService} from '@services/user/user.service';
import {SocialAuthService} from '@abacritt/angularx-social-login';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Data, NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {filter, map, mergeMap} from 'rxjs';
import {AuthService} from '@services/auth.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  @HostListener('window:resize')
  onResize() {
    this.appService.checkWidth();
  }

  constructor(
    private appService: AppService,
    private userService: UserService,
    private authService: AuthService,
    private socialAuthService: SocialAuthService,
    private titleService: Title,
    private route: ActivatedRoute,
    private i18n: TranslateService,
    private router: Router,
  ) {
    this.userService.initUser();
    this.socialAuthService.authState.subscribe((user) => {
      if (user) {
        this.authService.googleAuth(user, this.route.snapshot.queryParams['returnUrl']);
      }
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.getLastActivatedRoute(this.route)),
        mergeMap(({data}) => data),
      )
      .subscribe((data) => this.setTitle(data));
  }

  ngOnInit() {
    this.appService.checkWidth();
  }

  private setTitle(data: Data): void {
    const titleKey = data['titleKey'];
    let pageTitle = 'ДрукАрмія';
    if (titleKey) {
      pageTitle = `${this.i18n.instant(titleKey)} - ${pageTitle}`.replace('<br>', ' ');
    }
    this.titleService.setTitle(pageTitle);
  }

  private getLastActivatedRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }

    return route;
  }
}
