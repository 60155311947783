import {
  animate,
  animation,
  AnimationMetadata,
  state,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';

// https://stackblitz.com/edit/angular-animation-directive-ad?file=src%2Fapp%2Fdirectives%2Fanimation.directive.ts

const fadeIn: AnimationMetadata[] = [style({opacity: 0}), animate('400ms ease-in', style({opacity: 1}))];

const fadeOut: AnimationMetadata[] = [style({opacity: '*'}), animate('400ms ease-in', style({opacity: 0}))];

export const transitionAnimation = animation([
  style({
    height: '{{ height }}',
    opacity: '{{ opacity }}',
    backgroundColor: '{{ backgroundColor }}',
  }),
  animate('{{ time }}'),
]);

export const triggerAnimation = trigger('openClose', [
  transition('open => closed', [
    useAnimation(transitionAnimation, {
      params: {
        height: 0,
        opacity: 1,
        backgroundColor: 'red',
        time: '1s',
      },
    }),
  ]),
]);

export const InOutOpacityAnimation = (duration = 250) => [
  trigger('fadeInOut', [
    state('void', style({opacity: 0})), // Initial state when element enters
    state('*', style({opacity: 1})), // Final state when element leaves

    // Transition from void (entering) to * (leaving) with animation
    transition('void => *', animate(`${duration}ms ease-in-out`)),

    // Transition from * (leaving) to void (entering) with animation
    transition('* => void', animate(`${duration}ms ease-in-out`)),
  ]),
];
