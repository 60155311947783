import {Pipe, PipeTransform} from '@angular/core';
import {DiscussionProduct} from '@app/pages/platform/my-tasks/models';
import {CustomerProduct, PressmanProduct} from '@core/models';
import {environment} from '@env/environment';

@Pipe({
  name: 'productImg',
})
export class ProductImgPipe implements PipeTransform {
  transform(
    product: CustomerProduct | PressmanProduct | DiscussionProduct,
    size: 'thumb_url' | 'medium_url' | 'landing_url' = 'medium_url',
    type: 'customer' | 'pressman' = 'customer',
  ): any {
    const defCrs = environment.defaults.productImage;
    if (!product) {
      return defCrs;
    }
    if (type == 'customer') {
      const p = product as CustomerProduct;
      return (
        p.main_image?.[size] ||
        p.main_image?.medium_url ||
        p.main_image?.landing_url ||
        p.main_image?.url ||
        p.images?.[0]?.[size] ||
        p.images?.[0]?.medium_url ||
        p.images?.[0]?.landing_url ||
        p.images?.[0]?.url ||
        defCrs
      );
    } else {
      const p = product as PressmanProduct;
      return (
        p.pressman_main_image?.[size] ||
        p.pressman_main_image?.medium_url ||
        p.pressman_main_image?.landing_url ||
        p.pressman_main_image?.url ||
        p.pressman_images?.[0]?.[size] ||
        p.pressman_images?.[0]?.medium_url ||
        p.pressman_images?.[0]?.landing_url ||
        p.pressman_images?.[0]?.url ||
        defCrs
      );
    }
  }
}
