import {CommonModule} from '@angular/common';
import {NgModule, PipeTransform, Type} from '@angular/core';
import {ProductImgPipe} from './product-img.pipe';
import {FileSizePipe} from '@app/shared/pipes/file-size.pipe';
import {KgWeightPipe} from './kg-weight.pipe';

const SHARED_PIPES: Type<PipeTransform>[] = [ProductImgPipe, FileSizePipe, KgWeightPipe];

@NgModule({
  imports: [CommonModule],
  declarations: [...SHARED_PIPES],
  exports: [...SHARED_PIPES],
})
export class SharedPipesModule {}
