import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-task-progress',
  templateUrl: './task-progress.component.html',
  styleUrls: ['./task-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskProgressComponent {
  @Input() progress = 0;
}
