export enum OrderState {
  DRAFT = 'draft',
  AWAITING_DOCUMENTS = 'awaiting_documents',

  SENT = 'sent',
  RECEIVED = 'received',

  APPROVED = 'approved',
  ARRIVED = 'arrived',
  FULFILLED = 'fulfilled',

  CANCELLED = 'cancelled',
  sender_refusal = 'sender_refusal',
}
