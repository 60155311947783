<ng-container *ngIf="navigate; else logo">
  <a [routerLink]="[homeUrl$ | async]">
    <ng-container [ngTemplateOutlet]="logo"></ng-container>
  </a>
</ng-container>

<ng-template #logo>
  <div class="logo">
    <img class="logo-light" [src]="'assets/logo/light-' + (lang$ | async) + '.svg'" alt="" />
    <img class="logo-dark" [src]="'assets/logo/dark-' + (lang$ | async) + '.svg'" alt="" />
  </div>
</ng-template>
