import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ThemeOptions} from '@core/enums/theme-options.enum';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private readonly STORAGE_KEY = 'theme';
  private readonly THEME_DARK_BODY_CLASS = 'dark-theme';

  private currentThemeSubject = new BehaviorSubject<ThemeOptions>(
    (localStorage.getItem(this.STORAGE_KEY) as ThemeOptions) || ThemeOptions.LIGHT,
  );

  currentTheme$ = this.currentThemeSubject.asObservable();

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.setTheme(this.currentThemeSubject.getValue());
  }

  setTheme(theme: ThemeOptions): void {
    this.currentThemeSubject.next(theme);
    localStorage.setItem(this.STORAGE_KEY, theme);
    const body = this.document.body;
    if (body) {
      if (theme === ThemeOptions.DARK) {
        body.classList.add(this.THEME_DARK_BODY_CLASS);
      } else {
        body.classList.remove(this.THEME_DARK_BODY_CLASS);
      }
    }
  }

  toggleTheme(): void {
    const newTheme = this.currentThemeSubject.getValue() === ThemeOptions.DARK ? ThemeOptions.LIGHT : ThemeOptions.DARK;
    this.setTheme(newTheme);
  }
}
