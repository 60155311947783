import {Injectable} from '@angular/core';
import dayjs from 'dayjs';

const JWT_EXPIRATION = 7 * 24 * 55 * 60; // 5 min prior expiration, just in case
const JWT_EXPIRATION_KEY = 'jwt_expires_at';
const JWT_TOKEN_KEY = 'jwtToken';
@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private _token = '';

  get token(): string {
    return this._token;
  }

  set token(value: string) {
    this._token = value;
  }

  constructor() {
    this.token = this.getToken() || '';
  }

  public setSession(jwtToken: string) {
    if (!jwtToken) return;
    this.token = jwtToken;
    document.cookie =
      '_drar_jwt=' + jwtToken + ';domain=.drukarmy.org.ua;path=/;secure;expires=Fri, 31 Dec 2100 23:59:59 GMT';
    const expiresAt = dayjs().add(JWT_EXPIRATION, 'second');
    localStorage.setItem(JWT_TOKEN_KEY, jwtToken);
    localStorage.setItem(JWT_EXPIRATION_KEY, JSON.stringify(expiresAt.valueOf()));
  }

  public removeSession() {
    this.token = '';
    localStorage.removeItem(JWT_TOKEN_KEY);
    localStorage.removeItem(JWT_EXPIRATION_KEY);
  }

  public isTokenInActualState() {
    const expiredIn = this.getExpiration();
    return expiredIn && dayjs().isBefore(expiredIn);
  }

  public isExpiredToken() {
    return !this.isTokenInActualState();
  }

  public getToken() {
    const token = localStorage.getItem(JWT_TOKEN_KEY);
    document.cookie =
      '_drar_jwt=' + token + ';domain=.drukarmy.org.ua;path=/;secure;expires=Fri, 31 Dec 2100 23:59:59 GMT';
    return token;
  }

  public getExpiration() {
    const expiration = localStorage.getItem(JWT_EXPIRATION_KEY);
    if (!expiration) return false;
    const expiresAt = JSON.parse(expiration);
    return dayjs(expiresAt);
  }
}
