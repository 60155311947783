export enum TTNStatus {
  UNTRACKED = 'untracked',
  DELETED_NOT_FOUND = 'deleted_not_found',
  HEADED = 'headed',
  IN_CITY = 'in_city',
  ARRIVED = 'arrived',
  RECEIVED = 'received',
  SENDER_REFUSAL = 'sender_refusal',
  RECIPIENT_REFUSAL = 'recipient_refusal',
  ADDRESS_CHANGED = 'address_changed',
  STORAGE_DISCONTINUED = 'storage_discontinued',
  RECEIVED_AND_RETURNING = 'received_and_returning',
  RECIPIENT_NOT_RESPONDING = 'recipient_not_responding',
  RECIPIENT_REDIRECTED = 'recipient_redirected',
}
