import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ColumnDefinition} from '@app/shared/models/column-definition';

@Component({
  selector: 'app-table-column-selector',
  templateUrl: './table-column-selector.component.html',
  styleUrls: ['./table-column-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableColumnSelectorComponent {
  @Input() icon: string = 'settings';
  @Input() translationKeyPrefix: string = '';
  @Input() columns: ColumnDefinition[] = [];
  @Output() visibleColumnsChange = new EventEmitter<ColumnDefinition[]>();

  protected get visibleColumns(): ColumnDefinition[] {
    return this.columns.filter((cd) => cd.visible);
  }

  columnToggled($event: MouseEvent): void {
    $event?.stopPropagation();
    this.visibleColumnsChange.emit([...this.visibleColumns]);
  }
}
