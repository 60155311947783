import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'kgWeight',
})
export class KgWeightPipe implements PipeTransform {
  transform(weightGrams: number, precision: number = 2): string {
    return `${(weightGrams / 1000).toFixed(precision)}`;
  }
}
