import {ThemeService} from '@services/theme.service';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {UserService} from '@services/user/user.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent {
  homeUrl$ = this.userService.homeUrl$;
  theme$ = this.themeService.currentTheme$;

  constructor(
    private userService: UserService,
    private themeService: ThemeService,
  ) {}
}
