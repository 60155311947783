<ng-container *ngIf="pagesTotal > 1">
   <button
     *ngIf="currentPage < pagesTotal && !activePages.includes(pagesTotal)"
     mat-button
     color="primary"
     class="paginatorHeader"
     (click)="showMore()"
   >
     {{'paginator.show.more' | translate}}
   </button>

  <div class="pagination-buttons">
    <button
      mat-flat-button
      [disabled]="activePages.includes(1)"
      (click)="prev()"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>

    <ng-container *ngFor="let page of getPagesToShow()">
      <button
        mat-flat-button
        [class.selectedPage]="page === currentPage"
        [disabled]="activePages.includes(page)"
        (click)="selectCurrentPage(page)"
      >
        {{ page }}
      </button>
    </ng-container>

    <button
      mat-flat-button
      [disabled]="activePages.includes(pagesTotal)"
      (click)="next()"
    >
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</ng-container>
