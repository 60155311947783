<div class="maintenance">
  <div class="maintenance-content">
    <h1>{{ 'maintenance.title' | translate }}</h1>
    <h3>{{ 'maintenance.desc' | translate }}</h3>
  </div>
  <video [muted]="'muted'" loop autoplay [src]="'/assets/images/503.webm'"></video>
  <div class="actions">
    <a [href]="homeUrl$ | async">
      <mat-icon aria-hidden="false" aria-label="Back arrow" fontIcon="keyboard_backspace"></mat-icon>
      {{ 'go_home' | translate }}
    </a>
  </div>
</div>
