<!-- <div matRipple [matMenuTriggerFor]="localeMenu" class="langTrigger">
  {{ locale | titlecase }}
</div> -->
<button mat-icon-button [matMenuTriggerFor]="localeMenu">
  {{ locale.title }}
</button>
<mat-menu #localeMenu="matMenu">
  <button mat-menu-item *ngFor="let langData of languageList" (click)="changeSiteLanguage(langData.code)">
    <mat-icon>
      <img
        [src]="'/assets/images/icons/flags/' + langData.code + '.svg' | lowercase"
        [alt]="langData.code"
        width="20"
        height="20"
      />
    </mat-icon>
    <span>{{ langData.label }}</span>
  </button>
</mat-menu>
