import {Component, Input} from '@angular/core';

function isImage(type: string) {
  return type.split('/')[0] === 'image';
}

interface PreviewFile {
  file: File;
  preview?: string | ArrayBuffer | null;
}

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent {
  @Input() labelKey = 'checkout.order.load-document';
  @Input() mimeTypes?: string[];

  items: PreviewFile[] = [];

  /**
   * on file drop handler
   */
  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(event: any) {
    this.prepareFilesList(event.target.files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.items.splice(index, 1);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: File[]) {
    for (const file of files) {
      if (!this.mimeTypes || this.mimeTypes.includes(file.type)) {
        this.items.push({file});
      }
    }
    this.items.forEach((item) => {
      if (isImage(item.file.type)) {
        const reader = new FileReader();
        reader.onload = (evt) => {
          if (evt.target) {
            item.preview = evt.target.result;
          }
        };
        reader.readAsDataURL(item.file);
      }
    });
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: number, decimals: number = 0) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  get files() {
    return this.items.map((item) => item.file);
  }
}
