export const COLORS: {name: string; style: string}[] = [
  {
    name: 'any',
    style: 'background: linear-gradient(#001cf6, #fd1d1d, #45fc74);',
  },
  {
    name: 'indesc',
    style: `background: repeating-linear-gradient(
      #fff,
      #fff 1px,
      #000 1px,
      #000 2px
   );`,
  },
  {
    name: 'black',
    style: 'background: #000;',
  },
  {
    name: 'khaky',
    style: 'background: #6F7455;',
  },
  {
    name: 'dark',
    style: 'background: linear-gradient(#6F7455, #000);',
  },
  {
    name: 'light',
    style: 'background: linear-gradient(#fff, #999);',
  },
  {
    name: 'brown',
    style: 'background: #914900;',
  },
  {
    name: 'red',
    style: 'background: #F60202;',
  },
  {
    name: 'white',
    style: 'background: #fff;',
  },
  {
    name: 'green',
    style: 'background: #007C01;',
  },
  {
    name: 'gray',
    style: 'background: #6A6B6C;',
  },
  {
    name: 'yellow',
    style: 'background: #F7F703;',
  },
  {
    name: 'transparent',
    style: `
      background: linear-gradient(90deg, #333 5px, white 5px),
      linear-gradient(90deg, white 5px, #333 5px),
      linear-gradient(90deg, #333 5px, white 5px),
      linear-gradient(90deg, white 5px, #333 5px);
      background-position: 0 0, 0 5px, 0 10px, 0 15px;
      background-repeat: repeat-x;
      background-size: 10px 5px, 10px 5px, 10px 5px;`,
  },
  {
    name: 'blue',
    style: 'background: #0000F6;',
  },
  {
    name: 'orange',
    style: 'background: #EA4F0D;',
  },
  {
    name: 'purple',
    style: 'background: #A329F1;',
  },
  {
    name: 'light_blue',
    style: 'background: #17CDFE;',
  },
];
