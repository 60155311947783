<button mat-icon-button [matMenuTriggerFor]="menu" class="icon-btn table-config-menu">
  <mat-icon>{{ icon }}</mat-icon>
  <mat-menu #menu="matMenu" xPosition="before">
    <span class="table-config-menu-label">{{ 'column-settings' | translate }}</span>
    <div class="table-config-menu-options">
      <mat-checkbox
        class="option"
        *ngFor="let column of columns; let i = index"
        (click)="columnToggled($event)"
        [(ngModel)]="column.visible"
      >
        {{ translationKeyPrefix + column.id | translate }}
      </mat-checkbox>
    </div>
  </mat-menu>
</button>
