export enum Color {
  ANY = 'any',
  DARK = 'dark',
  LIGHT = 'light',
  WHITE = 'white',
  BLACK = 'black',
  TRANSPARENT = 'transparent',
  KHAKY = 'khaky',
  GREEN = 'green',
  BROWN = 'brown',
  BLUE = 'blue',
  RED = 'red',
  YELLOW = 'yellow',
  ORANGE = 'orange',
  PURPLE = 'purple',
  LIGHT_BLUE = 'light_blue',
  GRAY = 'gray',
}
